import { useEffect } from "react";
import logo from "../../Assets/images/logo.png";
import "../../Assets/css/media.css";

function ChatWidget() {
  useEffect(() => {
    const chatWidget = document.createElement("chat-widget");
    chatWidget.setAttribute("location-id", "tIGaxNLdcXbFOproEHCT");
    document.body.appendChild(chatWidget);

    const script = document.createElement("script");
    script.src = "https://widgets.leadconnectorhq.com/loader.js";
    script.setAttribute(
      "data-resources-url",
      "https://widgets.leadconnectorhq.com/chat-widget/loader.js"
    );
    script.async = true;
    document.body.appendChild(script);

    const interval = setInterval(() => {
      const chatWidgetFrame = document.querySelector("chat-widget");

      if (chatWidgetFrame) {
        const shadowRoot = chatWidgetFrame.shadowRoot;

        if (shadowRoot) {
          const profileImg = shadowRoot.querySelector("img");
          if (profileImg) {
            profileImg.src = logo;
            profileImg.style.width = "50px";
            profileImg.style.height = "50px";
            profileImg.style.borderRadius = "50%";
          }

          const allImages = shadowRoot.querySelectorAll("img");
          allImages.forEach((img) => {
            img.src = logo;
            img.style.width = "50px";
            img.style.height = "50px";
            img.style.borderRadius = "50%";
          });

          // Change background color for the heading

          const heading = shadowRoot.querySelector(
            ".lc_text-widget_heading--root"
          );
          if (heading) {
            heading.style.background = "#4169e0";
          }

          // Change background color for the button
          const primaryButton = shadowRoot.querySelector(
            ".lc_text-widget .btn.btn-primary"
          );
          if (primaryButton) {
            primaryButton.style.backgroundColor = "#4169e0"; // Change to your preferred color
            primaryButton.style.borderColor = "#4169e0"; // If you want to change border color as well
          }

          const chatBubble = shadowRoot.querySelector(".chat-bubble-icon");
          if (chatBubble) {
            chatBubble.style.backgroundColor = "#4169e0";
            chatBubble.style.width = "60px";
            chatBubble.style.height = "60px";
            chatBubble.style.borderRadius = "50%";
            chatBubble.style.display = "flex";
            chatBubble.style.alignItems = "center";
            chatBubble.style.justifyContent = "center";
            chatBubble.style.boxShadow = "0px 4px 6px rgba(0, 0, 0, 0.2)";
            chatBubble.style.cursor = "pointer";
            chatBubble.style.transition = "all 0.3s ease-in-out";

            chatBubble.style.position = "fixed";
            chatBubble.style.bottom = "20px";
            chatBubble.style.right = "20px";

            chatBubble.style.backgroundImage = `url(${logo})`;
            chatBubble.style.backgroundSize = "contain";
            chatBubble.style.backgroundPosition = "center";
            chatBubble.style.backgroundRepeat = "no-repeat";

            chatBubble.addEventListener("mouseenter", () => {
              chatBubble.style.transform = "scale(1.1)";
              chatBubble.style.backgroundColor = "#4169e0";
            });

            chatBubble.addEventListener("mouseleave", () => {
              chatBubble.style.transform = "scale(1)";
              chatBubble.style.backgroundColor = "#4169e0";
            });
          }

          const chatContainer = shadowRoot.querySelector(".chat-container");
          if (chatContainer) {
            chatContainer.style.backgroundColor = "#4169e0";
            chatContainer.style.borderRadius = "10px";
            chatContainer.style.padding = "15px";
            chatContainer.style.transition = "background-color 0.3s ease";

            // **Set background color for the entire form (or any section)**
            const formSection = shadowRoot.querySelector(".form-section"); // Assuming the form section has class name "form-section"
            if (formSection) {
              formSection.style.backgroundColor = "#4169e0"; // Change background color for the whole form
            }

            // Change background color of "Have a Question" section (if part of form-section)
            const questionSection =
              shadowRoot.querySelector(".question-section"); // Assuming class name is "question-section"
            if (questionSection) {
              questionSection.style.backgroundColor = "#4169e0"; // Light gray background for the question section
            }

            const observer = new MutationObserver(() => {
              if (chatContainer.style.display !== "none") {
                chatContainer.style.backgroundColor = "#ffffff";
              } else {
                chatContainer.style.backgroundColor = "#4169e0";
              }
            });

            observer.observe(chatContainer, {
              attributes: true,
              childList: true,
              subtree: true,
            });
          }

          const sendButton = shadowRoot.querySelector("button");
          if (sendButton) {
            sendButton.style.backgroundColor = "#4169e0";
            sendButton.style.color = "#fff";
            sendButton.style.borderRadius = "50%";
            sendButton.style.padding = "10px 15px";
            sendButton.style.fontWeight = "medium";
            sendButton.style.cursor = "pointer";
            sendButton.style.fontSize = "16px";

            const updateButtonWidth = () => {
              if (window.innerWidth > 600) {
                sendButton.style.width = "45px";
                sendButton.style.height = "45px";
              } else if (window.innerWidth > 380) {
                sendButton.style.width = "40px";
                sendButton.style.height = "40px";
              } else {
                sendButton.style.width = "50px";
                sendButton.style.height = "50px";
              }
            };
            updateButtonWidth();
            window.addEventListener("resize", updateButtonWidth);

            sendButton.addEventListener("click", () => {
              sendButton.style.backgroundColor = "#4169e0";
            });

            sendButton.addEventListener("mouseenter", () => {
              sendButton.style.backgroundColor = "#4169e0";
            });

            sendButton.addEventListener("mouseleave", () => {
              sendButton.style.backgroundColor = "#4169e0";
            });
          }

          clearInterval(interval);
        }
      }
    }, 1000);

    return () => {
      document.body.removeChild(chatWidget);
      document.body.removeChild(script);
      clearInterval(interval);
    };
  }, []);

  return null;
}

export default ChatWidget;
