import React, { useEffect, useState } from "react";
import logo from "../../Assets/images/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AlignJustify, X, ArrowLeft } from "lucide-react";
import "../../App.css";
import "../../Assets/css/media.css";
import {
  Description,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { IoCloseCircleOutline } from "react-icons/io5";

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isFeedback, setIsFeedback] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://link.msgsndr.com/js/form_embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div className="sticky top-0 z-50 bg-[#111926] text-white border-b border-[#1B212C]">
        <div className="containers ">
          <div className=" xl:flex hidden justify-between items-center h-[92px]">
            <div
              onClick={() => {
                navigate("/");
              }}
              className="cursor-pointer"
            >
              <div className="flex items-center">
                <img
                  src={logo}
                  className="xl:w-20 xl:h-20 lg:w-16 lg:h-16"
                  alt="logo"
                />
                <h2 className="4xl:text-2xl  xl:text-lg lg:text-sm font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-800 to-cyan-300">
                  CAR WASH NEAR ME
                </h2>
              </div>
            </div>
            <div>
              <div className="items-center flex justify-start ">
                <Link
                  to="/#howitwork"
                  className={`2xl:px-4 px-3 2xl:mx-1.5 mx-0.5 py-2.5 font-medium rounded-full ${
                    location.hash === "#howitwork"
                      ? "underline underline-offset-4"
                      : ""
                  }`}
                >
                  <div className="flex items-center whitespace-nowrap">
                    <p className="xl:text-base text-sm leading-7 outline-none">
                      How it Work
                    </p>
                  </div>
                </Link>
                <Link
                  to="/"
                  className={`2xl:px-4 px-3 2xl:mx-1.5 mx-0.5 py-2.5 font-medium rounded-full ${
                    location.pathname === "/aboutus"
                      ? "underline underline-offset-4"
                      : ""
                  }`}
                >
                  <div className="flex items-center whitespace-nowrap">
                    <p className=" xl:text-base text-sm leading-7 outline-none">
                      About Us
                    </p>
                  </div>
                </Link>
                <Link
                  to="/#faqs"
                  className={`2xl:px-4 px-3 2xl:mx-1.5 mx-0.5 py-2.5 font-medium rounded-full ${
                    location.hash === "#faqs"
                      ? "underline underline-offset-4"
                      : ""
                  }`}
                >
                  <div className="flex items-center whitespace-nowrap">
                    <p className=" xl:text-base text-sm leading-7 outline-none">
                      FAQs
                    </p>
                  </div>
                </Link>
                <Link
                  to="/#contact"
                  className={`2xl:px-4 px-3 2xl:mx-1.5 mx-0.5 py-2.5 font-medium rounded-full ${
                    location.hash === "#contact"
                      ? "underline underline-offset-4"
                      : ""
                  }`}
                >
                  <div className="flex items-center whitespace-nowrap">
                    <p className=" xl:text-base text-sm leading-7 outline-none">
                      Contact Us
                    </p>
                  </div>
                </Link>
                <Link
                  onClick={() => {
                    setIsFeedback(true);
                  }}
                  className="2xl:px-4 px-3 2xl:mx-1.5 mx-0.5 py-2.5 font-medium rounded-full"
                >
                  <div className="flex items-center whitespace-nowrap">
                    <p className="xl:text-base text-sm leading-7 outline-none">
                      Feedback
                    </p>
                  </div>
                </Link>
                <Link
                  to="/register_business"
                  className={`2xl:px-6 px-4 2xl:ml-5 ml-1 xl:py-3 py-2 font-medium border rounded-full ${
                    location.pathname === "/register_business" ? "" : ""
                  }`}
                >
                  <div className="flex items-center whitespace-nowrap">
                    <p className="xl:text-base text-sm leading-7 outline-none">
                      Register your business
                    </p>
                  </div>
                </Link>
                <Link
                  onClick={() => {
                    setIsDialogOpen(true);
                  }}
                  className="2xl:px-14 xl:px-12 px-10 2xl:ml-8 ml-4 xl:py-3 py-2 font-medium bg-[#4169E1]  rounded-full"
                >
                  <div className="flex items-center whitespace-nowrap">
                    <p className=" xl:text-base text-sm leading-7 outline-none">
                      Get The App
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div className=" xl:hidden h-[80px] flex justify-between items-center  ">
            <div
              onClick={() => navigate("/")}
              className="flex items-center cursor-pointer"
            >
              <img src={logo} className=" h-16" alt="logo" />
              <h2 className="xs:text-lg text-base font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-800 to-cyan-300 ">
                CAR WASH NEAR ME
              </h2>
            </div>
            <button
              onClick={() => setIsOpen(!isOpen)}
              className=" rounded-[11px] p-2"
            >
              {isOpen ? (
                <span className="text-white">
                  <X />
                </span>
              ) : (
                <span className="text-white">
                  <AlignJustify />
                </span>
              )}
            </button>
          </div>

          {isOpen && (
            <div
              className="fixed inset-0 bg-[#111926] bg-opacity-50 lg:hidden z-10 backdrop-blur-sm"
              onClick={() => setIsOpen(false)}
            ></div>
          )}

          <div
            className={`fixed xs:p-9 p-6 top-0 left-0 h-full bg-[#111926] z-20 transform overflow-auto sm:w-[300px] w-[70%] ${
              isOpen ? "translate-x-0" : "-translate-x-full"
            } transition-transform duration-500 ease-in-out xl:hidden`}
          >
            <div className="flex items-center">
              <div
                onClick={() => setIsOpen(false)}
                className="text-white cursor-pointer"
              >
                <ArrowLeft />
              </div>
            </div>
            <nav className="space-y-8 ">
              <div className="fonts-500 mt-10 ">
                <Link
                  to="/#howitwork"
                  onClick={() => setIsOpen(false)}
                  className={`flex items-center whitespace-nowrap ${
                    location.hash === "#howitwork"
                      ? "underline underline-offset-4"
                      : ""
                  }`}
                >
                  <p className="xs:text-base text-sm leading-7 outline-none">
                    How it Work
                  </p>
                </Link>
              </div>
              <div className="fonts-500">
                <Link
                  to="/"
                  onClick={() => setIsOpen(false)}
                  className={`flex items-center whitespace-nowrap ${
                    location.pathname === "/aboutus"
                      ? "underline underline-offset-4"
                      : ""
                  }`}
                >
                  <p className="xs:text-base text-sm leading-7 outline-none">
                    About Us
                  </p>
                </Link>
              </div>
              <div className="fonts-500">
                <Link
                  to="/#faqs"
                  onClick={() => setIsOpen(false)}
                  className={`flex items-center whitespace-nowrap ${
                    location.hash === "#faqs"
                      ? "underline underline-offset-4"
                      : ""
                  }`}
                >
                  <p className="xs:text-base text-sm leading-7 outline-none">
                    FAQs
                  </p>
                </Link>
              </div>

              <div className="fonts-500">
                <Link
                  to="/#contact"
                  onClick={() => setIsOpen(false)}
                  className={`flex items-center whitespace-nowrap ${
                    location.hash === "#contact"
                      ? "underline underline-offset-4"
                      : ""
                  }`}
                >
                  <p className="xs:text-base text-sm leading-7 outline-none">
                    Contact Us
                  </p>
                </Link>
              </div>
              <div className="fonts-500">
                <Link
                  onClick={() => {
                    setIsFeedback(true);
                    setIsOpen(false);
                  }}
                  className="flex items-center whitespace-nowrap"
                >
                  <p className="xs:text-base text-sm leading-7 outline-none">
                    Feedback
                  </p>
                </Link>
              </div>
              <div className="fonts-500">
                <Link
                  to="/register_business"
                  onClick={() => setIsOpen(false)}
                  className="flex items-center whitespace-nowrap"
                >
                  <p className="xs:text-base text-sm leading-7 outline-none border rounded-full xs:px-6 px-4 xs:py-2 py-1">
                    Register your business
                  </p>
                </Link>
              </div>
              <div className="fonts-500 ">
                <Link
                  onClick={() => {
                    setIsDialogOpen(true);
                    setIsOpen(false);
                  }}
                  className="flex items-center whitespace-nowrap "
                >
                  <p className=" xs:text-base text-sm leading-7 outline-none xs:px-16 px-12 xs:py-2 py-1 bg-[#4169E1] rounded-full">
                    Get The App
                  </p>
                </Link>
              </div>
            </nav>
          </div>

          <Dialog
            open={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
            className="relative z-50"
          >
            <DialogBackdrop className="fixed inset-0 bg-black/30 backdrop-blur-[2px]" />
            <div className="fixed inset-0 flex w-screen items-center justify-center  p-10">
              <DialogPanel className="max-w-2xl space-y-3 rounded-xl bg-white py-7 px-7 ">
                <DialogTitle className="font-bold text-center text-base fonts-600">
                  Almost there!
                </DialogTitle>
                <Description className="text-sm text-center  mt-1 fonts-300">
                  The Car Wash Near Me app is launching soon !
                </Description>
                <div className="flex justify-center">
                  <button
                    className="px-16 py-2 bg-[#4169e0] text-white  text-base rounded-xl fonts-500  mt-3"
                    onClick={() => setIsDialogOpen(false)}
                  >
                    Okay
                  </button>
                </div>
              </DialogPanel>
            </div>
          </Dialog>

          <Dialog
            open={isFeedback}
            onClose={() => setIsFeedback(false)}
            className="relative z-50"
          >
            <DialogBackdrop className="fixed inset-0 bg-black/30 backdrop-blur-[2px]" />
            <div className="fixed inset-0 flex w-screen items-center justify-center  p-4">
              <DialogPanel className="max-w-2xl w-full space-y-3 rounded-xl bg-white sm:py-6 sm:px-6 px-5 py-5">
                <div className="flex items-center w-full">
                  <DialogTitle className="font-bold text-2xl font-semibold flex-grow text-center">
                    Feedback Form
                  </DialogTitle>
                  <IoCloseCircleOutline
                    className="ml-auto text-3xl cursor-pointer"
                    onClick={() => {
                      setIsFeedback(false);
                    }}
                  />
                </div>
                <Description
                  className="text-sm text-center mt-1 fonts-300 sm:h-[600px] h-[450px] overflow-auto "
                  style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
                >
                  {" "}
                  <iframe
                    src="https://api.leadconnectorhq.com/widget/survey/o6Xmb5E7nebqe0mTIcYp"
                    style={{ border: "none", width: "100%", height: "100vh" }}
                    scrolling="no"
                    id="o6Xmb5E7nebqe0mTIcYp"
                    title="Survey 001"
                    className=""
                  />
                </Description>
              </DialogPanel>
            </div>
          </Dialog>
        </div>
      </div>
    </>
  );
}
export default Header;
