import React, { useState, useEffect } from "react";
import "../../App.css";
import arrow from "../../Assets/images/Arrow.png";

const ScrollToTopButton = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <img
      src={arrow}
      alt="arrow"
      onClick={scrollToTop}
      className=" sm1:w-[45px] sm1:h-[45px] w-[40px] h-[40px] bottom-5  sm1:right-[72px] right-16 z-50 fixed cursor-pointer"
      style={{
        display: visible ? "" : "none",
      }}
    />
  );
};

export default ScrollToTopButton;
