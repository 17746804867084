import React from "react";
import footerlogo from "../../Assets/images/logo.png";
import icon1 from "../../Assets/images/icon1.png";
import icon2 from "../../Assets/images/icon2.png";
import icon4 from "../../Assets/images/icon4.png";
import icon5 from "../../Assets/images/icon5.png";
import locationimg from "../../Assets/images/location.png";
import messageimg from "../../Assets/images/Message.png";
import mobileimg from "../../Assets/images/mobile.png";
import { FaTiktok } from "react-icons/fa6";
import { useLocation } from "react-router-dom";

function Footer() {
  const location = useLocation();

  return (
    <>
      <section>
        <div
          className={`bg-[#111927] text-white ${
            location.pathname === "/register_business"
              ? "pt-10"
              : "sm:pt-[400px] pt-10"
          }`}
        >
          <div className="containers">
            <img
              src={footerlogo}
              alt="footer logo"
              className="4xl:w-[137px] 4xl:h-[137px] lg:w-32 lg:h-32 sm1:w-24 sm1:h-24 w-[60px] h-[60px] lg:mb-8 sm1:mb-6 mb-5"
            />
            <div className="grid grid-cols-12 md:gap-x-10 sm1:gap-x-5 sm1:gap-y-0 gap-y-8">
              <div className="sm1:col-span-5 col-span-12">
                <h2 className="4xl:text-[40px] 2xl:text-4xl md1:text-3xl sm1:text-2xl text-xl leading-10 xl:leading-[62px] font-medium xl:mb-8 sm1:mb-6 mb-5">
                  Car Wash Near Me
                </h2>
                <p className="xl:text-lg md:text-base sm1:text-sm text-base leading-5 font-medium xl:mb-10 mb-8">
                  Your trusted app to find the best car wash near you!
                </p>
                <div className="flex items-center md:gap-x-2.5 gap-x-1 ">
                  <button className="border rounded-full border-[#FFFFFF1F] p-2">
                    <a
                      href="https://www.facebook.com/share/1629q5no8e/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={icon1}
                        alt="facebook icon"
                        className="md:w-5 md:h-5 sm1:w-4 sm1:h-4 w-5 h-5"
                      />
                    </a>
                  </button>
                  <button className="border rounded-full border-[#FFFFFF1F] md:p-2 sm1:p-1.5 p-2  ">
                    <a
                      href="https://www.instagram.com/carwashnearmeuae?utm_source=qr&igsh=MWFsMzA5ZXVoeW56bw=="
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={icon2}
                        alt="instagram icon"
                        className="md:w-5 md:h-5 sm1:w-4 sm1:h-4 w-5 h-5"
                      />
                    </a>
                  </button>
                  <button className="border rounded-full border-[#FFFFFF1F] md:p-2 sm1:p-1.5 p-2  ">
                    <a
                      href="https://www.tiktok.com/@carwashnearmeuae?_t=ZS-8tPJnHKvurv&_r=1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaTiktok className="md:w-5 md:h-5 sm1:w-4 sm1:h-4 w-5 h-5" />
                    </a>
                  </button>
                  <button className="border rounded-full border-[#FFFFFF1F] md:p-2 sm1:p-1.5 p-2  ">
                    <a
                      href="https://youtube.com/@carwashnearme-l7d?feature=shared"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={icon4}
                        alt="youtube icon"
                        className="md:w-5 md:h-5 sm1:w-4 sm1:h-4 w-5 h-5"
                      />
                    </a>
                  </button>
                  <button className="border rounded-full border-[#FFFFFF1F] md:p-2 sm1:p-1.5 p-2 ">
                    <a
                      href="https://www.linkedin.com/company/car-wash-near-me/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={icon5}
                        alt="linkedin icon"
                        className="md:w-5 md:h-5 sm1:w-4 sm1:h-4 w-5 h-5"
                      />
                    </a>
                  </button>
                </div>
              </div>
              <div className="sm1:col-span-3 col-span-12">
                <h6 className="underline decoration-1 underline-offset-8 md:text-xl text-lg font-semibold leading-6 sm1:mb-8 mb-5">
                  Quick links
                </h6>
                <ul>
                  <li>
                    <a
                      href="/#howitwork"
                      className="lg:text-base md:text-sm sm1:text-xs text-base font-medium leading-5"
                    >
                      How it Work
                    </a>
                  </li>
                  <li className="lg:my-10 my-5">
                    <a
                      href="/"
                      className="lg:text-base md:text-sm sm1:text-xs text-base font-medium leading-5 "
                    >
                      About Us
                    </a>
                  </li>
                  <li>
                    <a
                      href="/#faqs"
                      className="lg:text-base md:text-sm sm1:text-xs text-base font-medium leading-5"
                    >
                      FAQs
                    </a>
                  </li>
                </ul>
              </div>
              <div className="sm1:col-span-3 col-span-12">
                <h6 className="underline decoration-1 underline-offset-8 md:text-xl text-lg font-semibold leading-6 sm1:mb-8 mb-5">
                  Contact Us
                </h6>
                <div className="flex items-center md:gap-x-3.5 sm1:gap-x-2.5 gap-x-3.5">
                  <img
                    src={locationimg}
                    alt="locationicon"
                    className="md:w-6 md:h-6 sm1:w-5 sm1:h-5 w-6 h-6"
                  />
                  <p className="lg:text-base md:text-sm sm1:text-xs text-sm font-medium sm1:w-auto w-56">
                    Sharjah Media City, Sharjah, United Arab Emirates
                  </p>
                </div>
                <div className="flex items-center md:gap-x-3.5 sm1:gap-x-2.5 gap-x-3.5 lg:my-10 my-8">
                  <img
                    src={messageimg}
                    alt="locationicon"
                    className="md:w-6 md:h-6 sm1:w-5 sm1:h-5 w-6 h-6"
                  />
                  <a
                    href="mailto:info@carwashnearme.ae"
                    className="lg:text-base md:text-sm sm1:text-xs text-sm font-medium cursor-pointer"
                  >
                    info@carwashnearme.ae
                  </a>
                </div>
                <div className="flex items-center md:gap-x-3.5 sm1:gap-x-2.5 gap-x-3.5 mb-8">
                  <img
                    src={mobileimg}
                    alt="locationicon"
                    className="md:w-6 md:h-6 sm1:w-5 sm1:h-5 w-6 h-6"
                  />
                  <p className="lg:text-base md:text-sm sm1:text-xs text-sm font-medium">
                    +971502755004
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="border-t border-[#1B212C] " />
        </div>
      </section>

      <section>
        <div className="bg-[#111927]">
          <div className="containers">
            <p className="text-decoration-none fonts-400 md:text-base text-sm text-white flex justify-center text-center items-center h-16">
              Copyright © 2024 Car Wash Near Me Company | All Rights Reserved
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
export default Footer;
